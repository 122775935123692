import useSWR from 'swr'
import { transferUser, User } from './user'
import { API_DOMAIN, fetcherFullRequest, init, Likes } from './common'
import { formatArticleDate, urlToHtmlLink } from '../components'

export const COMMENT_TYPE = 'comment--comment'
export type NewsArticleComment = {
  text: string
  date: string
  author: User | undefined
  id: string
  drupalInternalNid: string
  type: string
  parentId?: string
  replies?: Array<NewsArticleComment>
} & { likes: Likes }
export function transferNewsArticleComment(
  comment: any,
  authors: User[],
  comments: any[]
): NewsArticleComment {
  return {
    text: comment?.attributes?.comment_body?.value,
    date: formatArticleDate(comment.attributes.created),
    author: authors?.find(
      (a) => a.id === comment?.relationships?.uid?.data?.id
    ),
    id: comment?.id,
    drupalInternalNid: comment?.attributes.drupal_internal__cid,
    parentId: comment?.relationships?.pid?.data?.id,
    replies: comments
      ?.filter((c) => c?.relationships?.pid?.data?.id === comment?.id)
      .map((c) => ({
        ...transferNewsArticleComment(c, authors, []),
        parentId: comment?.id
      })),
    likes: {
      likes: comment?.attributes?.field_like?.likes,
      dislikes: comment?.attributes?.field_like?.dislikes,
      clickedBy: comment?.attributes?.field_like?.clicked_by
    },
    type: COMMENT_TYPE
  }
}

export function useArticleComments(id: string | undefined) {
  const { data, error, mutate } = useSWR(
    id
      ? [
          `${API_DOMAIN}/jsonapi/comment/comment?filter[entity_id.id]=${id}&include=uid,uid.user_picture&sort=-created`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )
  return {
    comments: data
      ? data?.data
          ?.map((comment: any) =>
            transferNewsArticleComment(
              comment,
              (data.included || []).map((user: User) =>
                transferUser(user, data.included)
              ),
              data?.data
            )
          )
          ?.filter(({ parentId }: NewsArticleComment) => !parentId)
      : data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function addArticleComment(
  id: string,
  text: string,
  type: string,
  pid?: string
) {
  const commentToReply = pid
    ? {
        pid: {
          data: {
            type: COMMENT_TYPE,
            id: pid
          }
        }
      }
    : {}
  return fetch(
    `${API_DOMAIN}/jsonapi/comment/comment?filter[entity_id.id]=${id}`,
    {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/vnd.api+json'
      },
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: COMMENT_TYPE,
          attributes: {
            comment_body: {
              value: urlToHtmlLink(text)
            },
            entity_type: 'node',
            field_name: 'comment'
          },
          relationships: {
            entity_id: {
              data: {
                type,
                id
              }
            },
            ...commentToReply
          }
        }
      })
    }
  )
}

export function updateArticleComment(
  id: string,
  text: string,
  parentType: string,
  parentId: string
) {
  return fetch(`${API_DOMAIN}/jsonapi/comment/comment/${id}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: COMMENT_TYPE,
        id,
        attributes: {
          comment_body: {
            value: urlToHtmlLink(text)
          },
          entity_type: 'node',
          field_name: 'comment'
        },
        relationships: {
          entity_id: {
            data: {
              type: parentType,
              id: parentId
            }
          }
        }
      }
    })
  })
}

export function deleteArticleComment(id: string) {
  return fetch(`${API_DOMAIN}/jsonapi/comment/comment/${id}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE'
  })
}
