import {
  Avatar,
  AvatarImage,
  Box,
  Body7,
  Document24,
  Body4,
  Body5
} from '@sefar/design-system'
import { Media, PurchaseRequisitionOrder } from '../types'
import {
  useCostCenters,
  useDeliveryAddresses,
  useLedgers,
  useProjects,
  usePurchaseRequisitionAttachments
} from '../../api'
import { Table, TD, TH, TR } from '../../../my-content/common'
import { useUserByEmployeeId } from '../../../../api'
import AvatarPlaceholder from '../../../../../assets/avatar-placeholder-blue.jpg'
import { useMemo } from 'react'
import mime from 'mime-types'
import { PURCHASE_API_BASE_URL } from '../../api/consts'

type OrderDetailsTableProps = {
  orderDetails: PurchaseRequisitionOrder
}

export const OrderDetailsTable = ({ orderDetails }: OrderDetailsTableProps) => {
  const {
    urgency,
    requester: requesterId,
    deliveryAddress,
    receiver: receiverId,
    approver: approverId,
    costCenter,
    project,
    ledgerAccount,
    buyerInternalContact: internalContactId,
    additionalInfo,
    attachments
  } = orderDetails
  const { data: files } = usePurchaseRequisitionAttachments(attachments)
  const { user: requester } = useUserByEmployeeId(requesterId)
  const { user: receiver } = useUserByEmployeeId(receiverId)
  const { user: approver } = useUserByEmployeeId(approverId)
  const { user: internalContact } = useUserByEmployeeId(internalContactId)
  const { data: warehouseData } = useDeliveryAddresses()
  const deliveryAddresses = useMemo(() => {
    const warehouses = warehouseData?.data
    const warehouseMap: { [a: string]: string } = {}
    if (warehouses?.length) {
      for (const wrh of warehouses) {
        if (wrh.warehouse) {
          warehouseMap[wrh.warehouse] = wrh.warehouseDesc
        }
      }
    }
    return warehouseMap
  }, [warehouseData])
  const { data: ledgers = {} } = useLedgers()
  const { data: costCenters = {} } = useCostCenters({
    ledgerAccount: orderDetails.ledgerAccount,
    project: orderDetails.project
  })
  const { data: projects = {} } = useProjects({
    ledgerAccount: orderDetails.ledgerAccount,
    costCenter: orderDetails.costCenter
  })
  return (
    <Box css={{ maxWidth: '100%' }}>
      <Box css={{ overflowX: 'auto', mb: '$8' }}>
        <Table css={{ borderCollapse: 'collapse' }}>
          <thead>
            <TR header>
              <TH css={{ width: 88 }}>
                <Body7>Urgent</Body7>
              </TH>
              <TH css={{ width: 296 }}>
                <Body7>Requester</Body7>
              </TH>
              <TH css={{ width: 172 }}>
                <Body7>Delivery address</Body7>
              </TH>
              <TH css={{ width: 142 }}>
                <Body7>Receiver</Body7>
              </TH>
              <TH css={{ width: 124 }}>
                <Body7>Approver</Body7>
              </TH>
            </TR>
          </thead>
          <tbody>
            <TR
              css={{ borderBottom: '1px solid $neutralLighten80', h: '5rem' }}
            >
              <TD>{urgency ? 'Yes' : 'No'}</TD>
              <TD>
                {requester ? (
                  <>
                    <Avatar css={{ mr: '$2' }}>
                      <AvatarImage
                        src={requester.image || AvatarPlaceholder}
                        alt={`${requester.firstName || '–'} ${
                          requester.lastName || '–'
                        }`}
                      />
                    </Avatar>
                    {`${requester.firstName} ${requester.lastName}`}
                  </>
                ) : (
                  '–'
                )}
              </TD>
              <TD>
                {(deliveryAddress && deliveryAddresses[deliveryAddress]) || '–'}
              </TD>
              <TD>
                {receiver ? (
                  <>
                    <Avatar css={{ mr: '$2' }}>
                      <AvatarImage
                        src={receiver.image || AvatarPlaceholder}
                        alt={`${receiver.firstName || '–'} ${
                          receiver.lastName || '–'
                        }`}
                      />
                    </Avatar>
                    {`${receiver.firstName} ${receiver.lastName}`}
                  </>
                ) : (
                  '–'
                )}
              </TD>
              <TD>
                {approver ? (
                  <>
                    <Avatar css={{ mr: '$2' }}>
                      <AvatarImage
                        src={approver.image || AvatarPlaceholder}
                        alt={`${approver.firstName || '–'} ${
                          approver.lastName || '–'
                        }`}
                      />
                    </Avatar>
                    {`${approver.firstName} ${approver.lastName}`}
                  </>
                ) : (
                  '–'
                )}
              </TD>
            </TR>
          </tbody>
        </Table>
      </Box>
      <Box css={{ overflowX: 'auto', mb: '$8' }}>
        <Table css={{ borderCollapse: 'collapse' }}>
          <thead>
            <TR header>
              <TH css={{ width: 88 }}>
                <Body7>Cost center</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Project</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Ledger account</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Buyer internal contact</Body7>
              </TH>
              <TH css={{ width: 144 }}>
                <Body7>Additional info</Body7>
              </TH>
            </TR>
          </thead>
          <tbody>
            <TR
              css={{ borderBottom: '1px solid $neutralLighten80', h: '5rem' }}
            >
              <TD>{(costCenter && costCenters[costCenter]) || '–'}</TD>
              <TD>{(project && projects[project]) || '–'}</TD>
              <TD>{(ledgerAccount && ledgers[ledgerAccount]) || '–'}</TD>
              <TD>
                {internalContact ? (
                  <>
                    <Avatar css={{ mr: '$2' }}>
                      <AvatarImage
                        src={internalContact.image || AvatarPlaceholder}
                        alt={`${internalContact.firstName || '–'} ${
                          internalContact.lastName || '–'
                        }`}
                      />
                    </Avatar>
                    {`${internalContact.firstName} ${internalContact.lastName}`}
                  </>
                ) : (
                  '–'
                )}
              </TD>
              <TD css={{ whiteSpace: 'pre-wrap' }}>{additionalInfo || '–'}</TD>
            </TR>
          </tbody>
        </Table>
      </Box>
      {files && files.length ? (
        <Box
          css={{
            minHeight: 252,
            backgroundColor: '$neutralLighten97',
            borderRadius: '$4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: '$4'
          }}
        >
          <Box
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {files.map((file: Media) => (
              <Box
                as="a"
                href={`${PURCHASE_API_BASE_URL}/purchase-requisitions/file/download/${file.file_id}`}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '$3',
                  background: '',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'inherit'
                }}
                key={file.file_id}
              >
                <Box
                  css={{
                    position: 'relative',
                    w: 106,
                    h: 95,
                    background: '$neutralLighten90',
                    borderRadius: '$4',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Document24 />
                  <Body5 fontWeight="bold" css={{ textTransform: 'uppercase' }}>
                    {mime.extension(file.file_fileType)}
                  </Body5>
                </Box>
                <Body4
                  css={{
                    color: '$neutral',
                    textAlign: 'start',
                    pl: '$4',
                    width: 200,
                    wordBreak: 'break-all'
                  }}
                  fontWeight="bold"
                >
                  {file.file_name}
                </Body4>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
