import {
  Box,
  CheckmarkIcon16,
  ChevronDownIcon16,
  Container,
  ContainerPadding,
  SearchIcon20
} from '@sefar/design-system'
import { CSS, styled } from '@stitches/react'
import ScrollToBottom, {
  useScrollToBottom,
  useSticky
} from 'react-scroll-to-bottom'
import ChatBotAnswerIcon from '../../../assets/chatbot-answer.svg?react'
import { ChatMessage, MessageMetaData } from '../../pages/ai-assist/api/types'
import CopyAnswerIcon from '../../../assets/copy-ai.svg?react'
import { useCopyToClipboard } from 'react-use'
import removeMd from 'remove-markdown'
import Markdown from 'react-markdown'

const ChatBox = styled(ScrollToBottom, {
  width: '100%',
  height: '100%',
  flex: 1
})

type MessageProps = {
  text: string
  isBotAnswer?: boolean
  resourcesAmount?: number
  onReviewResources?: () => void
}

const Message = ({
  text,
  isBotAnswer,
  resourcesAmount = 0,
  onReviewResources
}: MessageProps) => {
  const [clipboardState, copyToClipboard] = useCopyToClipboard()
  return (
    <Box
      css={{
        d: 'flex',
        justifyContent: isBotAnswer ? 'flex-start' : 'flex-end',
        py: '$4'
      }}
    >
      <Box
        css={{
          backgroundColor: isBotAnswer ? 'none' : '#F7F7F8',
          p: '$4 $1',
          '@lg': {
            p: '$4 $8'
          },
          borderRadius: 24,
          d: 'flex'
        }}
      >
        {isBotAnswer && (
          <Box css={{ flexShrink: 0, mr: '$4' }}>
            <ChatBotAnswerIcon />
          </Box>
        )}
        <Box
          css={{
            lineHeight: 1.5,
            textAlign: isBotAnswer ? 'left' : 'right',
            whiteSpace: 'pre-wrap',
            mb: '-$4',
            '& h1': {
              fontSize: '$h2Lg',
              fontWeight: 'bold',
              mb: '$4'
            },
            '& h2': {
              fontSize: '$h3Lg',
              fontWeight: 'bold',
              mb: '$4'
            },
            '& h3': {
              fontSize: '$h4Lg',
              fontWeight: 'bold',
              mb: '$4'
            },
            '& p': {
              mb: '$4',
              fontSize: '$body4'
            },
            '& b': {
              fontWeight: 'bold'
            },
            '& strong': {
              fontWeight: 600
            },
            '& ol': {
              listStyleType: 'decimal',
              ml: '$12'
            },
            '& ul': {
              listStyleType: 'disc'
            }
          }}
        >
          <Markdown>{text}</Markdown>
          {/* {messageComponents} */}
          {isBotAnswer && (
            <Box css={{ d: 'flex', alignItems: 'center' }}>
              <Box
                onClick={() => {
                  copyToClipboard(removeMd(text))
                }}
                css={{
                  transition: 'background-color .3s',
                  cursor: 'pointer',
                  w: 36,
                  height: 36,
                  d: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#EFEFEF'
                  }
                }}
              >
                {clipboardState.value ? (
                  <CheckmarkIcon16 />
                ) : (
                  <CopyAnswerIcon />
                )}
              </Box>

              {onReviewResources && (
                <Box
                  onClick={onReviewResources}
                  css={{
                    d: 'flex',
                    alignItems: 'center',
                    w: 'fit-content',
                    p: '10px',
                    gap: '10px',
                    cursor: 'pointer'
                  }}
                >
                  <SearchIcon20 width={16} height={16} />
                  {resourcesAmount} sources
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const ChatFeed = ({
  css,
  messages,
  streamedMessage,
  onReviewResources
}: {
  css: CSS
  messages: ChatMessage[]
  streamedMessage?: ChatMessage | null
  onReviewResources: (meta?: MessageMetaData[]) => void
}) => {
  return (
    <Box css={css}>
      <ChatBox
        css={{ pb: 100, '& .followButton': { display: 'none' } }}
        followButtonClassName="followButton"
      >
        <ChatContent
          messages={messages}
          streamedMessage={streamedMessage}
          onReviewResources={onReviewResources}
        />
      </ChatBox>
    </Box>
  )
}

const ChatContent = ({
  messages,
  streamedMessage,
  onReviewResources
}: {
  messages: ChatMessage[]
  streamedMessage?: ChatMessage | null
  onReviewResources: (meta?: MessageMetaData[]) => void
}) => {
  const [sticky] = useSticky()
  const scrollToBottom = useScrollToBottom()

  const getResourcesAmount = (metadata?: MessageMetaData[]) => {
    let urls: string[] = []

    metadata?.forEach((metadataItem) => {
      const sourceUrl = metadataItem.meta.source
      if (!urls.includes(sourceUrl)) {
        urls.push(sourceUrl)
      }
    })

    return urls.length
  }

  return (
    <ContainerPadding css={{ height: '100%', position: 'relative' }}>
      <Container css={{ height: '100%' }}>
        {messages?.length
          ? messages?.map((m) => (
              <Message
                text={m.message}
                isBotAnswer={m.isAI}
                resourcesAmount={getResourcesAmount(m.metadata)}
                onReviewResources={
                  !!m.metadata ? () => onReviewResources(m.metadata) : undefined
                }
                key={m.id}
              />
            ))
          : null}
        {streamedMessage ? (
          <Message
            text={streamedMessage.message}
            isBotAnswer={streamedMessage.isAI}
            resourcesAmount={getResourcesAmount(streamedMessage.metadata)}
            onReviewResources={
              !!streamedMessage?.metadata
                ? () => onReviewResources(streamedMessage.metadata)
                : undefined
            }
          />
        ) : null}
        <Box css={{ height: 100, width: '100%' }} />
        {!sticky && (
          <Box
            css={{
              position: 'sticky',
              bottom: 10,
              d: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              onClick={() => scrollToBottom()}
              css={{
                borderRadius: '50%',
                width: 36,
                height: 36,
                border: '1px solid $neutral',
                cursor: 'pointer',
                backgroundColor: '$white',
                d: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <ChevronDownIcon16 />
            </Box>
          </Box>
        )}
      </Container>
    </ContainerPadding>
  )
}
