import { useState, MouseEvent, FormEvent, useEffect } from 'react'
import { useMedia, useQueue } from 'react-use'
import {
  ArrowRightIcon16,
  Box,
  Heading1,
  Heading2,
  Input,
  LayoutLeftIcon24,
  NewThreadIcon24,
  Spinner,
  TextArea
} from '@sefar/design-system'
import { ChatFeed } from '../../components/ai-assist/chat-feed'
import { HistoryDrawer } from '../../components/ai-assist/history-drawer'
import { ResourcesDrawer } from '../../components/ai-assist/resources-drawer'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import {
  useAskVector,
  useCreateThread,
  useThreadMessagesHistory,
  useThreadsHistory
} from './api'
import { ChatMessage, MessageMetaData } from './api/types'
import { useNavigate, useParams } from 'react-router-dom'
import { AIAssistPageUrl } from '../../app'
import AIPlaceholder from '../../../assets/ai-placeholder.svg?react'

export const AIAssistPage = () => {
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false)
  const [currentThreadId, setCurrentThreadId] = useState<number>()
  const { threadId } = useParams()
  const [showMessageResources, setShowMessageResources] =
    useState<MessageMetaData[]>()
  const { historyData = [], mutate: mutateThreadsHistory } = useThreadsHistory()
  const { data = [] } = useThreadMessagesHistory({ threadId: currentThreadId })
  const { handleCreateThread } = useCreateThread()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const navigate = useNavigate()
  const onToggleHistoryDrawerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsHistoryDrawerOpen(!isHistoryDrawerOpen)
  }
  const onCreateThreadClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setCurrentThreadId(undefined)
    navigate(`/${AIAssistPageUrl}`)
    setShowMessageResources(undefined)
    setMessages([])
  }
  const [text, setText] = useState<string>('')
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [streamedMessage, setStreamedMessage] = useState<ChatMessage | null>(
    null
  )
  const { handleSubmit, response: vectorResponse, isLoading } = useAskVector()

  const onReviewResources = (meta?: MessageMetaData[]) => {
    if (meta?.length) {
      setShowMessageResources(meta)
    }
  }

  const onChangeThread = (threadId: number) => {
    setShowMessageResources(undefined)
    setMessages([])
    setCurrentThreadId(threadId)
  }

  const onFormSubmit = async () => {
    if (typeof text === 'string' && text.length) {
      const textToSend = text
      setText('')
      setMessages((prev) => [
        ...prev,
        {
          message: textToSend,
          isAI: false
        }
      ])
      let threadId = currentThreadId
      if (!currentThreadId) {
        const title = text.trim().split(' ').slice(0, 5).join(' ')
        threadId = await handleCreateThread(title)
        await mutateThreadsHistory()
        navigate(`/${AIAssistPageUrl}/${threadId}`)
      }
      await handleSubmit(textToSend, threadId)
    }
  }
  useEffect(() => {
    if (threadId) {
      const threadNumber = parseInt(threadId)
      if (typeof threadNumber === 'number') {
        setCurrentThreadId(threadNumber)
      }
    }
  }, [threadId])
  useEffect(() => {
    let messageStream = ''
    for (const ch of vectorResponse) {
      if (ch.type === 'MESSAGE') {
        messageStream += ch.data?.chunk
      }
      if (ch.type === 'META') {
        setStreamedMessage((prev) => {
          if (!prev) {
            return {
              id: new Date().getUTCMilliseconds(),
              message: '',
              isAI: true,
              metadata: ch.data
            }
          } else {
            return { ...prev, metadata: ch.data }
          }
        })
      }
      if (ch.type === 'DONE' && streamedMessage) {
        messageStream = ''
        setStreamedMessage(null)
        setMessages((prev) => {
          return [...prev, streamedMessage]
        })
      }
    }
    if (messageStream) {
      setStreamedMessage((prev) => {
        if (!prev) {
          return {
            id: new Date().getUTCMilliseconds(),
            message: messageStream,
            isAI: true
          }
        } else {
          return { ...prev, message: messageStream }
        }
      })
    }
  }, [vectorResponse])

  useEffect(() => {
    if (data?.length) {
      setMessages(data)
    }
  }, [data])

  return (
    <Box
      css={{
        w: '100%',
        h: 'calc(100% - 64px)',
        d: 'flex'
      }}
    >
      <HistoryDrawer
        isOpen={isHistoryDrawerOpen}
        onToggleDrawer={() => setIsHistoryDrawerOpen(!isHistoryDrawerOpen)}
        onChangeThread={onChangeThread}
        historyData={historyData}
      />
      <Box
        css={{
          d:
            (isHistoryDrawerOpen || showMessageResources) && !isLg
              ? 'none'
              : 'flex',
          flexDirection: 'column',
          w: '100%',
          height: '100%'
        }}
      >
        {threadId && !messages?.length ? (
          <Box
            css={{
              h: '100%',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner />
          </Box>
        ) : messages?.length ? (
          <Box
            css={{
              flex: '1 1 0',
              overflow: 'hidden'
            }}
          >
            <ChatFeed
              css={{
                h: '100%',
                flexGrow: 1
              }}
              messages={messages}
              streamedMessage={streamedMessage}
              onReviewResources={onReviewResources}
            />
          </Box>
        ) : (
          <Box
            css={{
              h: 'calc(100% - 90px)',
              d: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AIPlaceholder />
            <Heading2 css={{ mt: '$2' }}>How may I assist you today?</Heading2>
          </Box>
        )}
        <Box
          css={{
            d: 'flex',
            minHeight: '90px',
            p: isLg ? '18px 66px 18px 18px' : '18px',
            background: '#F5F5F5'
          }}
        >
          <Box
            css={{
              d: 'flex',
              alignItems: 'center',
              pl: isLg ? '12px' : '4px',
              pr: isLg ? '30px' : '28px',
              gap: isLg ? '40px' : '28px',
              height: 54
            }}
          >
            <Box
              css={{
                cursor: 'pointer',
                color: '$black',
                display: isHistoryDrawerOpen ? 'none' : 'block'
              }}
              onClick={onToggleHistoryDrawerClick}
            >
              <LayoutLeftIcon24 />
            </Box>
            <Box
              css={{ cursor: 'pointer', color: '$black' }}
              onClick={onCreateThreadClick}
            >
              <NewThreadIcon24 />
            </Box>
          </Box>

          <TextArea
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                onFormSubmit()
              }
            }}
            value={text}
            containerProps={{
              css: { mx: 'auto', w: '100%', '@lg': { maxWidth: '895px' } }
            }}
            css={{
              minHeight: '54px',
              maxHeight: text ? '180px' : 0,
              px: '24px',
              fontSize: '$body4 !important',
              borderRadius: 24,
              backgroundColor: '$white !important',
              overflow: 'auto'
            }}
            placeholder="Send a message to mySefar AI..."
            // endEnhancer={
            //   !isLg && (
            //     <Box
            //       as="button"
            //       css={{
            //         p: '10px',
            //         border: 0,
            //         borderRadius: '50%',
            //         lineHeight: 0,
            //         background: '#EFEFEF',
            //         color: '$neutralLighten60',
            //         cursor: 'pointer'
            //       }}
            //       type="submit"
            //     >
            //       <ArrowRightIcon16 />
            //     </Box>
            //   )
            // }
          />
        </Box>
      </Box>
      <ResourcesDrawer
        showMessageResources={showMessageResources}
        onCloseDrawer={() => setShowMessageResources(undefined)}
      />
    </Box>
  )
}
