import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { isPast } from 'date-fns'
import {
  API_DOMAIN,
  fetcherData,
  fetcherFullRequest,
  init,
  AUTH_PARAM_NAME,
  AUTH_PARAM_OAUTH_NAME
} from './common'
import { transferTools, Tool, TOOLS_TYPE } from './tools'
import { SpacesBookmarkSimple } from './space'
import { NewsArticle, ReviewStateEnum } from './news'
import { useConfigStore } from '../state/config'
import { FILE_DRUPAL_TYPE } from './media'
import { localStorageKeys } from '../constants'
import { SpaceArticle } from './space'

const CTX_MGMT = 'https://ch-system.ch.sefar.org/'

export const USER_TYPE = 'user--user'
export const USER_ROLE_TYPE = 'user_role--user_role'

export const USER_ROLES = {
  contentEditor: 'content_editor',
  contentReviewer: 'content_reviewer',
  administrator: 'administrator',
  ideaManager: 'idea_manager',
  approver: 'approver',
  purchaseRequisition: 'purchase_requisition'
}

export type User = {
  preferredLanguage: string
  id: string
  status: boolean
  email: string
  employeeid: string
  firstName: string
  lastName: string
  nickname: string
  jobTitle: string
  officePhone: string
  officeMobile: string
  emailForBusiness: string
  phoneForBusiness: string
  mobilePhoneForBusiness: string
  shortName: string
  department: string
  costCenter: string
  street: string
  postCode: string
  country: string
  location: string
  locationCode: string
  region: string
  organization: string
  tools: Tool[] | undefined
  roles: string[]
  permissions: string[]
  exitDate?: Date
  isUserDeactivated: boolean
  image: string
  spacesBookmarks: SpacesBookmarkSimple[] | undefined
  recentSearchResults: string[]
  internalUid?: string
  timezone?: string
}
export function useUser<User>(id?: string) {
  const { data, error } = useSWR(
    [
      id
        ? `${API_DOMAIN}/jsonapi/user/user/${id}?include=roles,user_picture,field_user_bookmarks`
        : '',
      init
    ],
    fetcherFullRequest
  )
  return {
    user: data?.data ? transferUser(data.data, data?.included) : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useUserByEmployeeId(employeeid?: string) {
  const { data, error } = useSWR(
    [
      employeeid
        ? `${API_DOMAIN}/jsonapi/user/user?filter[field_employeeid]=${employeeid}&include=roles,user_picture,field_user_bookmarks`
        : '',
      init
    ],
    employeeid ? fetcherFullRequest : null
  )
  return {
    user: data?.data?.[0]
      ? transferUser(data?.data[0], data?.included)
      : undefined,
    isLoading: !error && !data,
    isError: error
  }
}

export function patchUser(
  id: string,
  attributes: Record<string, string> | undefined,
  relationships: Record<string, any>
) {
  return fetch(`${API_DOMAIN}/jsonapi/user/user/${id}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: USER_TYPE,
        id,
        attributes: {
          ...attributes
        },
        ...relationships
      }
    })
  }).then((res) => res && res.json())
}

export function patchUserTools(id: string, tools: Tool[] = []) {
  const preparedTools: { type: string; id: string }[] = tools.map(
    (tool: Tool) => ({
      type: TOOLS_TYPE,
      id: tool.id
    })
  )
  const relationships = {
    relationships: {
      field_tools: {
        data: preparedTools
      }
    }
  }
  return patchUser(id, undefined, relationships)
}

export function patchUserSpacesBookmarks(
  id: string | undefined,
  bookmarks: SpacesBookmarkSimple[] = []
) {
  const relationships = {
    relationships: {
      field_user_bookmarks: {
        data: bookmarks
      }
    }
  }
  return id && patchUser(id, undefined, relationships)
}

function activeUserFilter(user?: User) {
  return !user?.isUserDeactivated
}

export function useUserTeam(email: string) {
  const { data, error } = useSWR<{
    users: (User & { uuid: string })[]
    superiors: (User & { uuid: string })[]
    team: (User & { uuid: string })[]
  }>(
    [
      email
        ? `${API_DOMAIN}/user_entity/getAllUsersWithEmail?email=${email}`
        : '',
      init
    ],
    fetcherFullRequest
  )
  return {
    team: data && {
      users: Object.values(data.users ?? [])
        .map(({ uuid: id, ...attributes }) => transferUser({ id, attributes }))
        .filter(activeUserFilter),
      manager: Object.values(data.superiors ?? []).map(
        ({ uuid: id, ...attributes }) => transferUser({ id, attributes })
      )[0],
      team: data?.team
        ? Object.values(data.team)
            .map(({ uuid: id, ...attributes }) =>
              transferUser({ id, attributes })
            )
            .filter(activeUserFilter)
        : []
    },
    isLoading: !error && !data,
    isError: error
  }
}

const getUserRoles = (roles: any[]) => {
  return roles?.map((role) => role?.attributes?.drupal_internal__id) ?? []
}

const getUserPermissions = (roles: any[]) => {
  return [
    ...new Set(roles?.flatMap((role) => role?.attributes?.permissions) ?? [])
  ]
}

function createUserImageUrlFromIncluded(id: string, included: any[]) {
  if (!id || !included) {
    return ''
  }
  const asset = included.find(
    (asset) => asset.type === FILE_DRUPAL_TYPE && asset.id === id
  )
  const url =
    asset?.attributes?.uri?.url ?? asset?.attributes?.image_style_uri?.medium
  return url
}

function normalizeExitDate(rawExitDate?: string) {
  if (rawExitDate && new Date(rawExitDate).getTime() > 0) {
    return new Date(rawExitDate)
  }
  return undefined
}

export function transferUser(data: any, included: any = []): User {
  const roles = included?.filter(
    ({ type }: { type: string }) => type === USER_ROLE_TYPE
  )
  const tools = included?.filter(
    ({ type }: { type: string }) => type === TOOLS_TYPE
  )

  const exitDate = normalizeExitDate(data?.attributes?.field_exit_date)
  const isUserDeactivated = exitDate
    ? isPast(exitDate)
    : data?.attributes?.status === false || data?.attributes?.status === '0'
  return (
    data?.attributes && {
      id: data.id,
      status: data.attributes?.status,
      email: data.attributes?.mail,
      employeeid: `${data.attributes?.field_employeeid}`,
      preferredLanguage: data.attributes?.preferred_langcode,
      firstName:
        data.attributes?.field_first_name?.value ||
        data.attributes?.field_first_name,
      lastName:
        data.attributes?.field_last_name?.value ||
        data.attributes?.field_last_name,
      nickname:
        (data.attributes?.field_nickname?.value ||
          data.attributes?.field_nickname) ??
        '',
      jobTitle:
        data.attributes?.field_job_title?.value ||
        data.attributes?.field_job_title,
      officePhone: data.attributes?.field_office_phone?.value,
      officeMobile:
        data.attributes?.field_office_mobile?.value ||
        data.attributes?.field_office_mobile,
      emailForBusiness: data.attributes?.field_email_for_business,
      phoneForBusiness: data.attributes?.field_phone_for_business,
      mobilePhoneForBusiness: data.attributes?.field_mobile_business,
      shortName: data.attributes?.field_abbreviated_names,
      department: data.attributes?.field_department?.value,
      costCenter: data.attributes?.field_cost_centre,
      street: data.attributes?.field_location_street,
      postCode: data.attributes?.field_postal_code,
      country: data.attributes?.field_country?.value,
      location: data.attributes?.field_location_place,
      locationCode: data.attributes?.field_location?.value,
      region: data.attributes?.field_region?.value,
      organization: data.attributes?.field_organization,
      tools: transferTools(tools),
      roles: getUserRoles(roles),
      permissions: getUserPermissions(roles),
      spacesBookmarks: data.relationships?.field_user_bookmarks?.data,
      recentSearchResults: data.attributes?.field_user_recent_search,
      internalUid: data.attributes?.drupal_internal__uid,
      exitDate,
      isUserDeactivated,
      image: data?.attributes?.user_picture
        ? data?.attributes?.user_picture
        : createUserImageUrlFromIncluded(
            data.relationships?.user_picture?.data?.id,
            included
          ),
      timezone: data?.attributes?.timezone
    }
  )
}

export function useReviewers() {
  const { data, error } = useSWR(
    [
      `${API_DOMAIN}/jsonapi/user/user?filter[roles.meta.drupal_internal__target_id]=content_reviewer&include=user_picture`,
      init
    ],
    fetcherData
  )

  return {
    reviewers:
      data && data?.map((data: any) => transferUser(data, data?.included)),
    isLoading: !error && !data,
    isError: error
  }
}

export function login({ name, password }: { name: string; password: string }) {
  return fetch(`${API_DOMAIN}/user/login?_format=json`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      name,
      pass: password
    })
  })
    .then((res) => res?.json())
    .then((res) => {
      if (res?.csrf_token) {
        localStorage.setItem(AUTH_PARAM_NAME, res.csrf_token)
        localStorage.setItem('sefar-logout_token', res.logout_token)
        if (init!.headers) {
          ;(init.headers as Record<string, string>).Authorization =
            res.csrf_token(init.headers as Record<string, string>)[
              'X-CSRF-Token'
            ] = res.csrf_token
        }
        return res
      }
    })
    .catch((err) => console.error(err))
}

export function authorize(accessToken: string) {
  return fetch(`${API_DOMAIN}/user_entity/authorize?_format=json`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ accessToken, use_jwt: true })
  })
    .then((res) => res?.json())
    .then((res) => {
      if (res?.token) {
        localStorage.setItem(AUTH_PARAM_OAUTH_NAME, res?.token)
        ;(init.headers as Record<string, string>).Authorization =
          `Bearer ${res?.token}`
      } else if (res?.csrf_token) {
        localStorage.setItem(AUTH_PARAM_NAME, res.csrf_token)
        localStorage.setItem('sefar-logout_token', res.logout_token)
        if (init!.headers) {
          ;(init.headers as Record<string, string>).Authorization =
            res.csrf_token
          ;(init.headers as Record<string, string>)['X-CSRF-Token'] =
            res.csrf_token
        }
      }
      return res
    })
    .catch((err) => console.error(err))
}

export function loginByPhone(phone: string) {
  return fetch(`${API_DOMAIN}/user_entity/login_by_phone`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ phone })
  })
    .then((res) => res?.json())
    .catch((err) => console.error(err))
}

export function authorizeByPhone(token: string, code: string) {
  return fetch(`${API_DOMAIN}/user_entity/authorize_by_phone`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ code, token, use_jwt: true })
  })
    .then((res) => res?.json())
    .then((res) => {
      if (res?.token) {
        localStorage.setItem(AUTH_PARAM_OAUTH_NAME, res?.token)
        ;(init.headers as Record<string, string>).Authorization =
          `Bearer ${res?.token}`
      } else if (res?.csrf_token) {
        localStorage.setItem(AUTH_PARAM_NAME, res.csrf_token)
        localStorage.setItem('sefar-logout_token', res.logout_token)
        if (init!.headers) {
          ;(init.headers as Record<string, string>).Authorization =
            res.csrf_token
          ;(init.headers as Record<string, string>)['X-CSRF-Token'] =
            res.csrf_token
        }
      }

      return res
    })
    .catch((err) => console.error(err))
}

export function logout(): Promise<boolean> {
  return fetch(
    //`${API_DOMAIN}/user/logout?_format=json&token=${localStorage.getItem(
    //  'sefar-logout_token'
    // )}`,
    `${API_DOMAIN}/user/logout`,
    {
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-Token': localStorage.getItem(AUTH_PARAM_NAME)
          ? `${localStorage.getItem(AUTH_PARAM_NAME)}`
          : ''
      },
      credentials: 'include',
      method: 'POST'
    }
  )
    .then((res) => {
      const alreadyLoggedOut = res.status === 403
      // if (res.ok || alreadyLoggedOut) {
      //   localStorage.removeItem(AUTH_PARAM_NAME)
      //   localStorage.removeItem('sefar-logout_token')
      // }
      return alreadyLoggedOut || res.ok
    })
    .catch((e) => {
      return false
    })
}

export function useMe(id?: string | null) {
  const { id: storedId, setId } = useConfigStore()
  const uid = id || storedId

  if (!storedId && uid) {
    setId(uid)
  }

  const { data, error, mutate } = useSWRImmutable(
    [
      uid
        ? `${API_DOMAIN}/jsonapi/user/user?filter[id][condition][path]=uid&filter[id][condition][value]=${uid}&include=roles,user_picture,field_tools`
        : '',
      init
    ],
    fetcherFullRequest
  )
  return {
    me: data?.data ? transferUser(data?.data?.[0], data?.included) : data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function useDepartments() {
  const { data, error, mutate } = useSWRImmutable(
    [`${API_DOMAIN}/user_entity/users-departments`, init],
    fetcherFullRequest
  )

  return {
    departments: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function updateUserPicture(userId: string, file: File) {
  return fetch(`${API_DOMAIN}/jsonapi/user/user/${userId}/user_picture`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      ...init.headers,
      'Content-type': 'application/octet-stream',
      Accept: 'application/vnd.api+json',
      'Content-Disposition': `file; filename="${file.name}"`
    },
    body: new Blob([file], { type: 'application/octet-stream' })
  }).then((res) => res && res.json())
}

export const isRegularUser = (user?: User) => {
  return !user?.roles?.length
}

export const isUserReviewer = (user: User) => {
  return user?.roles?.includes(USER_ROLES.contentReviewer)
}

export const isUserEditor = (user: User) => {
  return user?.roles?.includes(USER_ROLES.contentEditor)
}

export function isNotAllowedToEditArticleInReview(
  user: User,
  article: NewsArticle
): boolean {
  return article?.reviewState === ReviewStateEnum['On review']
    ? user?.id !== article?.reviewer?.id &&
        !user?.roles?.some(
          (role) =>
            role === USER_ROLES.administrator ||
            role === USER_ROLES.contentReviewer
        )
    : false
}

export function isIdeaManager(user: User | undefined) {
  return user
    ? user?.roles?.some((role) => role === USER_ROLES.ideaManager)
    : false
}

export function isUserAuthorOfArticle(
  user: User,
  article: NewsArticle
): boolean {
  return article?.editor?.id === user?.id
}

export function isUserAuthorOfSpaceArticle(
  user: User,
  article: SpaceArticle
): boolean {
  let isEditor = false
  const isSpaceOwner = article?.owner?.id === user?.id
  if (article?.editors?.length) {
    const editorIds = article?.editors?.map(({ id }) => id)
    isEditor = editorIds.includes(user.id)
  }
  return isEditor || isSpaceOwner
}

export function getUrlFromSaml() {
  const res = fetch(`${API_DOMAIN}/user_entity/getSamlLogin`, {
    credentials: 'include',
    method: 'GET'
  }).then((res) => res?.json())

  return res
}

export const clearLocalStorage = () => {
  const chosenNewsCountry = localStorage.getItem(
    localStorageKeys.NEWS_CHOSEN_COUNTRY
  )
  localStorage.clear()
  chosenNewsCountry &&
    localStorage.setItem(
      localStorageKeys.NEWS_CHOSEN_COUNTRY,
      chosenNewsCountry
    )
}

export const isUserInNet = () => {
  return fetch(CTX_MGMT, {
    method: 'GET'
  })
}

export const getAuthJWT = () => {
  const res = fetch(`${API_DOMAIN}/order-request/auth`, {
    credentials: 'include',
    method: 'GET'
  }).then((res) => res?.json())
  return res
}

export const useAuthJWT = () => {
  const { data, error } = useSWR(
    [`${API_DOMAIN}/order-request/auth`, init],
    fetcherFullRequest
  )
  return {
    isLoading: !error && !data,
    data: data
  }
}
