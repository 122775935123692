import create, { StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'
import { User } from '../api'
import { defaultTranslations } from '../defaultTranslations'

export type AuthPayload = {
  jwt: string
  api_key: string
}
type ConfigStateData = {
  contentLang: string
  translations: Record<string, string>
  id: string
  me?: User
  auth?: AuthPayload
  isSpaceEditor: boolean
  pendingMessagesCount: number
  notificationBus?: WebSocket
}
type ConfigStateSetters = {
  setContentLang: (contentLang: string) => void
  setTranslations: (translations: Record<string, string>) => void
  setId: (lang: string) => void
  setMe: (me: User) => void
  setIsSpaceEditor: (isSpaceEditor: boolean) => void
  setAuth: (auth: AuthPayload) => void
  setPendingMessagesCount: (pendingMessagesCount: number) => void
  setNotificationBus: (notificationBus?: WebSocket) => void
  reset: () => void
}
export type ConfigState = ConfigStateData & ConfigStateSetters

type ConfigStatePersist = (
  config: StateCreator<ConfigState>,
  options: PersistOptions<ConfigState>
) => StateCreator<ConfigState>

const initialState: ConfigStateData = {
  id: '',
  contentLang: 'en',
  translations: defaultTranslations,
  me: undefined,
  isSpaceEditor: false,
  auth: {
    jwt: '',
    api_key: ''
  },
  pendingMessagesCount: 0,
  notificationBus: undefined
}

export const useConfigStore = create<ConfigState>(
  (persist as unknown as ConfigStatePersist)(
    (set) => ({
      ...initialState,
      setContentLang: (contentLang: string) => set({ contentLang }),
      setTranslations: (translations: Record<string, string>) =>
        set({ translations: { ...defaultTranslations, ...translations } }),
      setId: (id: string) => set({ id }),
      setIsSpaceEditor: (isSpaceEditor: boolean) => set({ isSpaceEditor }),
      setAuth: (auth: AuthPayload) => set({ auth }),
      setMe: (me: User) => set({ me }),
      setPendingMessagesCount: (pendingMessagesCount: number) =>
        set({ pendingMessagesCount }),
      setNotificationBus: (notificationBus?: WebSocket) =>
        set({ notificationBus }),
      reset: () => set(initialState)
    }),
    {
      name: 'sefar-storage',
      partialize: (state) => {
        return Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['notificationBus'].includes(key)
          )
        ) as ConfigState
      }
    }
  )
)
