import { Box, Button, Dialog, TextArea } from '@sefar/design-system'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../hooks/useTranslate'

export function UpdateCommentDialog({
  comment,
  onCommentUpdate,
  onClose
}: {
  comment?: { id: string; initialValue: string }
  onCommentUpdate: (updatedComment: any) => void
  onClose: () => void
}) {
  const [commentMessage, setCommentMessage] = useState<string | undefined>(
    comment?.initialValue
  )
  const { t } = useTranslate()

  useEffect(() => {
    if (comment?.initialValue) {
      setCommentMessage(comment?.initialValue)
    }
  }, [comment?.initialValue])

  return (
    <Dialog
      open={!!comment}
      title={t('field_update_comment')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      setOpenControlledDialog={(open) => !open && onClose()}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end', gap: '$3' }}>
          <Button onClick={() => onClose()} variant="secondary">
            {t('field_cancel')}
          </Button>
          <Button
            onClick={() => onCommentUpdate(commentMessage)}
            variant="primary"
          >
            {t('field_save')}
          </Button>
        </Box>
      }
    >
      <TextArea
        value={commentMessage}
        id="update-comment"
        name="update-comment"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setCommentMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
