import { Box, Dialog, LazyImage } from '@sefar/design-system'

export const ImagePreview = ({
  imgSrc,
  onClose
}: {
  imgSrc?: string
  onClose: () => void
}) => {
  if (!imgSrc) {
    return null
  }

  return (
    <Dialog
      open={!!imgSrc}
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
      contentProps={{
        css: {
          width: '90vw',
          height: '90vh',
          maxHeight: '90vh',
          cursor: 'zoom-out'
        }
      }}
      headerCss={{ display: 'none' }}
    >
      <Box css={{ w: '100%', height: '100%' }} onClick={onClose}>
        <LazyImage
          src={imgSrc}
          aspectRatio="auto"
          css={{ objectFit: 'contain' }}
        />
      </Box>
    </Dialog>
  )
}
