import axios from 'axios'
import { AI_API_BASE_URL } from './consts'
import { useState } from 'react'
import useSWR from 'swr'
import { parse } from 'partial-json'
import { LLMResponseChunk, ThreadsHistoryItem } from './types'
import { AUTH_PARAM_OAUTH_NAME } from '../../../api'

export const apiClient = axios.create({
  baseURL: AI_API_BASE_URL
})

const fetcher = (url: string) => apiClient.get(url).then((res) => res.data)

const parseChunks = (streamData: string) => {
  // Split the incoming stream data by line breaks
  const lines = streamData.split('\n')
  const events = []
  let currentEvent = null

  for (const line of lines) {
    if (line.startsWith('event:')) {
      // Extract event type
      currentEvent = { type: line.substring(6).trim(), data: '' }
      events.push(currentEvent)
    } else if (line.startsWith('data:')) {
      // Append data to the current event
      if (currentEvent) {
        currentEvent.data += line.substring(5).trim()
      }
    } else if (line.trim() === '') {
      // An empty line indicates the end of an event
      currentEvent = null
    }
  }

  return events.map((event) => ({
    type: event.type,
    data: parse(event.data)
  }))
}

export const useVectorHistory = ({ vector }: { vector: string }) => {
  const { data, error } = useSWR(
    vector ? `aiHistory/${vector}?page=1&limit=25` : null,
    fetcher
  )
  return {
    data: data?.data,
    error
  }
}

export const useThreadsHistory = () => {
  const { data, error, mutate } = useSWR<ThreadsHistoryItem[]>(
    'aiHistory/threads/user',
    fetcher
  )
  return {
    historyData: data,
    error,
    mutate
  }
}

export const useThreadMessagesHistory = ({
  threadId
}: {
  threadId?: number
}) => {
  const { data, error, mutate } = useSWR(
    threadId ? `aiHistory/threads/${threadId}/messages` : null,
    fetcher
  )
  return {
    data: data ?? [],
    error,
    mutate
  }
}

export const useCreateThread = () => {
  const handleCreateThread = async (
    title: string
  ): Promise<number | undefined> => {
    const res = await apiClient.post(`${AI_API_BASE_URL}/aiHistory/create`, {
      title
    })

    return res?.data.id
  }

  return { handleCreateThread }
}

export function useAskVector(): {
  isLoading: boolean
  isError: boolean
  response: LLMResponseChunk[]
  handleSubmit: (query: string, threadId?: number) => Promise<void>
} {
  const [response, setResponse] = useState<LLMResponseChunk[]>([])
  const [generating, setGenerating] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async (query: string, threadId?: number) => {
    try {
      if (query.trim().length === 0) {
        return
      }
      setGenerating(true)
      setResponse([])
      const authToken = localStorage.getItem(AUTH_PARAM_OAUTH_NAME)
        ? `Bearer ${localStorage.getItem(AUTH_PARAM_OAUTH_NAME)}`
        : ''
      const res = await fetch(`${AI_API_BASE_URL}/vectors/askClient`, {
        headers: {
          'content-type': 'application/json',
          accept: 'text/event-stream',
          authorization: authToken
        },
        method: 'POST',
        body: JSON.stringify({
          query,
          threadId,
          prompt:
            "You are a customer support agent working for an company and you respond only in the same language as the question was asked.\\nGive as much detail as possible when answering - no less than 150 words.\\nSometimes users do not provide information about a particular information; ask them to provide more if you feel that the question information is not enough.\\nUse the following pieces of context to answer the question at the end. Format your output as an commonmark.\\nIf you don't know the answer or there is no relevant info in the provided context, just say that you don't know; don't try to make up an answer.\\n\\n{context}\\n\\nQuestion: {question}\\nHelpful Answer:\"",
          indexName: 'AIAssistBasicProd'
        })
      })
      if (res.ok && res.body) {
        const reader = res.body.getReader()
        const processStream = async () => {
          // Transform to closure call
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const { done, value } = await reader.read()
            if (done) {
              setGenerating(false)
              break
            }
            const chunk = new TextDecoder('utf-8').decode(value)
            const parsedChunks = parseChunks(chunk)
            setResponse((prev) => [...prev, ...parsedChunks])
          }
        }
        processStream().catch((err) =>
          console.log('--stream reading error--', err)
        )
      } else {
        setError('Error getting response')
      }
    } catch (error) {
      setError(`error: ${error}`)
    }
  }
  return {
    handleSubmit,
    response,
    isLoading: generating,
    isError: !!error
  }
}
