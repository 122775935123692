import React, { useEffect, useState } from 'react'
import { Box, Spinner } from '@sefar/design-system'
import { useParams, useSearchParams, useLocation } from 'react-router-dom'
import {
  useSpaceArticle,
  isUserAuthorOfSpaceArticle,
  SpaceArticle
} from '../../api'
import { useConfigStore } from '../../state/config'
import { SpaceArticleDetailsEdit } from './space-article-details-edit'
import { SpaceArticleDetailsView } from './space-article-details-view'
import { spacesCreateArticlePageUrl } from '../../app'

export function SpaceArticleDetails() {
  const { me: user } = useConfigStore()
  const location = useLocation()
  const [isNew, setIsNew] = useState<boolean>(false)
  const [article, setArticle] = useState<SpaceArticle>()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [notAllowedToEdit, setNotAllowedToEdit] = useState<boolean>(false)
  const { contentLang: langConfig } = useConfigStore()
  const lang = params.lang || langConfig
  const {
    article: articleToEdit,
    isLoading,
    mutate
  } = useSpaceArticle(params?.articleId, lang)

  useEffect(() => {
    setNotAllowedToEdit(
      !user || (!isNew && !isUserAuthorOfSpaceArticle(user, articleToEdit))
    )
  }, [user?.id, articleToEdit, isNew])

  useEffect(() => {
    if (!params?.articleId) {
      setIsNew(true)
    }
  }, [article?.status, params?.articleId])

  useEffect(() => {
    if (location.pathname.includes(spacesCreateArticlePageUrl)) {
      setSearchParams(
        { editMode: 'true' },
        { replace: true, state: location.state }
      )
    }
  }, [])

  useEffect(() => {
    if (articleToEdit?.id) {
      setArticle(articleToEdit)
    }
  }, [articleToEdit?.id])

  return (
    <>
      {isLoading && !isNew ? (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          {!searchParams.get('editMode') || notAllowedToEdit ? (
            <SpaceArticleDetailsView
              article={articleToEdit}
              notAllowedToEdit={notAllowedToEdit}
              params={params}
              setSearchParams={setSearchParams}
              refetchArticle={mutate}
            />
          ) : (
            <SpaceArticleDetailsEdit
              article={article}
              params={params}
              setArticle={setArticle}
            />
          )}
        </>
      )}
    </>
  )
}
