import {
  BackLink,
  Body2,
  Body3,
  Body5,
  Box,
  Button,
  CheckmarkIcon16,
  Container,
  ContainerPadding,
  Grid,
  Heading1,
  Heading5,
  LazyImage,
  Line,
  Space,
  Tag,
  Video
} from '@sefar/design-system'
import { AnimatePresence, motion } from 'framer-motion'
import {
  ArticleInfo,
  ArticleInfoActions
} from '../../components/article/article-info'
import { ReadingStyles } from '../../components/TipTap/TipTap'
import { EditorContent, useEditor } from '@tiptap/react'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { TranslationNotification } from './translation-notification'
import { NEWS_ARTICLE_TYPE, NewsArticle, useNewsCategories } from '../../api'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TipTapLink from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import { ImagePreview } from '../../components/common/image-preview'
import VideoExtension from '../../components/TipTap/components/video'
import FileInContent from '../../components/TipTap/components/FileInContentUserView'
import { ColumnExtension } from '../../components/TipTap/components/ColumnExtention'

import { styled } from '../../../../../../stitches.config'
import { Link, Params, useLocation, useSearchParams } from 'react-router-dom'
import { Figure } from '../../components/TipTap/components/FigureUserView'
import {
  ArticleComments,
  MustReadBadge,
  getUserFullName,
  mixpanelAnalyticsHelper
} from '../../components'
import { newsPageUrl } from '../../app'
import { useTranslate } from '../../hooks/useTranslate'

const NewsArticleDetailsCard = styled('article', {
  pt: '$6',
  color: '$neutral'
})
const AnimatedNewsArticleDetailsCard = motion(NewsArticleDetailsCard)

const IS_MUST_READ_CONFIRMATION_ENABLED = false

export function NewsDetailsView({
  article,
  articleOriginal,
  articleTranslated,
  lang,
  mediaType,
  notAllowedToEdit,
  params,
  setArticle,
  setSearchParams,
  refetchArticle
}: {
  article: NewsArticle
  articleOriginal: NewsArticle
  articleTranslated: NewsArticle
  lang: string
  mediaType: string
  notAllowedToEdit: boolean
  params: Readonly<Params<string>>
  setArticle: Dispatch<SetStateAction<NewsArticle>>
  setSearchParams: ReturnType<typeof useSearchParams>[1]
  refetchArticle?: () => void
}) {
  const { t } = useTranslate()
  const location = useLocation()
  const commentsBox = useRef<HTMLHeadingElement>(null)
  const [enable, setEnable] = useState<boolean>(false)
  const [previewImageSrc, setPreviewImageSrc] = useState<string>()
  const [isConfirmedAsRead, setIsConfirmedAsRead] = useState(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(true)
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TipTapLink,
      Image,
      Figure,
      VideoExtension,
      FileInContent,
      ColumnExtension
    ],
    content: article?.text,
    editable: false,
    editorProps: {
      handleClickOn: (view, pos, node) => {
        if (node.attrs?.alt && node.attrs?.src) {
          node.attrs.src && setPreviewImageSrc(node.attrs.src)
        }
      }
    }
  })
  const { categories } = useNewsCategories()

  const assignedCategories = article?.categories?.map(
    (categoryKey) => categories[categoryKey]
  )

  const scrollToComments = () => {
    if (commentsBox.current) {
      commentsBox.current.scrollIntoView()
    }
  }

  const onConfirmAsRead = () => {
    if (isConfirmedAsRead) return
    setIsConfirmedAsRead(true)
  }

  const notification = articleOriginal ? (
    <TranslationNotification
      lang={lang}
      langOrigin={articleTranslated.langOrigin}
      onTranslation={() => {
        setEnable(true)
        setActiveAnimation(false)
        setTimeout(() => {
          setArticle((current: NewsArticle) =>
            current.title === articleOriginal.title
              ? articleTranslated
              : articleOriginal
          )
          setActiveAnimation(true)
        }, 200)
      }}
    />
  ) : (
    <></>
  )

  useEffect(() => {
    if (article?.text) {
      editor?.commands.setContent(article?.text)
      const authorFullName = getUserFullName(
        article.author?.firstName,
        article.author?.lastName
      )
      mixpanelAnalyticsHelper().track('News Article viewed', {
        articleId: article.id,
        articleTitle: article.title,
        articleAuthor: authorFullName,
        articleLanguage: article.lang,
        articleOriginLanguage: article.langOrigin
      })
    }
  }, [article?.text])

  return (
    <Box css={{ d: 'flex', flexDirection: 'column', height: '100%' }}>
      {notification}
      <ContainerPadding css={{ flex: 1 }}>
        <Container>
          <AnimatePresence>
            {article && activeAnimation && (
              <AnimatedNewsArticleDetailsCard
                transition={{
                  type: 'spring',
                  bounce: 0,
                  duration: 0.2
                }}
                initial={enable ? { opacity: 0 } : {}}
                animate={{ opacity: 1 }}
                exit={enable ? { opacity: 0 } : {}}
              >
                <Space mb="3XL">
                  <Grid>
                    <Box
                      css={{
                        gc: '2 / span 10',
                        gcSm: '1 / span 12',
                        d: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Box css={{ d: 'flex' }}>
                        <BackLink
                          as={Link}
                          paths={[t('field_news')]}
                          to={`/${newsPageUrl}#${article.id}`}
                          css={{ mb: '$10', '@sm': { mb: '$7' } }}
                        />
                      </Box>

                      <Box
                        css={{
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: '$8',
                          '@sm': {
                            mb: '$4'
                          }
                        }}
                      >
                        <Box
                          css={{ d: 'flex', alignItems: 'center', gap: '$6' }}
                        >
                          {article.isMustRead && <MustReadBadge />}
                          <Body5
                            css={{
                              color: '$neutralLighten30'
                            }}
                          >
                            {article.date}
                          </Body5>
                        </Box>

                        {!notAllowedToEdit && (
                          <Button
                            variant="secondary"
                            data-cy="editArticleButton"
                            onClick={() =>
                              setSearchParams(
                                { editMode: 'true' },
                                { replace: true, state: location.state }
                              )
                            }
                          >
                            Edit Article
                          </Button>
                        )}
                      </Box>

                      <Heading1 css={{ mb: '$4' }}>{article.title}</Heading1>
                      <Body2
                        dangerouslySetInnerHTML={{ __html: article.lead }}
                      />
                    </Box>

                    <Box
                      css={{
                        gc: '2 / span 10',
                        gcSm: '1 / span 12'
                      }}
                    >
                      <Line mt="MD" mb="3XS" />
                      <ArticleInfo
                        article={article}
                        refetchArticle={refetchArticle}
                        scrollToComments={scrollToComments}
                      />
                    </Box>
                  </Grid>
                  <Box
                    css={{
                      mt: '$9',
                      mb: '$20',
                      '@sm': {
                        mt: '$6',
                        mb: '$12'
                      }
                    }}
                  >
                    {mediaType === 'video' ? (
                      <Video src={article?.media?.url} />
                    ) : (
                      <LazyImage
                        css={{
                          borderRadius: '$3'
                        }}
                        aspectRatio="2 / 1"
                        src={article?.media?.url}
                        onClick={() => setPreviewImageSrc(article?.media?.url)}
                      />
                    )}
                  </Box>
                  <Grid>
                    <Body3
                      size="xl"
                      fontWeight="light"
                      css={{
                        gc: '2 / span 10',
                        gcSm: '1 / span 12',
                        '& .ProseMirror .column': {
                          overflow: 'auto',
                          borderRadius: '8px',
                          padding: '8px',
                          margin: '-8px'
                        },
                        '& .ProseMirror .column-block': {
                          width: '100%',
                          display: 'grid',
                          gridAutoFlow: 'column',
                          gridAutoColumns: '1fr',
                          gap: '24px',
                          padding: '8px 0'
                        },
                        ...ReadingStyles
                      }}
                    >
                      <EditorContent editor={editor} />
                      <ImagePreview
                        imgSrc={previewImageSrc}
                        onClose={() => setPreviewImageSrc(undefined)}
                      />
                    </Body3>
                  </Grid>

                  {IS_MUST_READ_CONFIRMATION_ENABLED && article.isMustRead && (
                    <Box
                      css={{
                        d: 'flex',
                        gap: '$6',
                        mt: '$22',
                        '@lg': { gap: '$8', mt: '$16' }
                      }}
                    >
                      <Box
                        css={{
                          w: '0.5rem',
                          background:
                            'linear-gradient(180deg, #F4943F 0.09%, #E32127 99.91%)',
                          flexShrink: 0
                        }}
                      />
                      <Box css={{ py: '$5', '@lg': { py: '$9' } }}>
                        <Heading5
                          css={{ my: '$2', '@lg': { mt: 0, mb: '$1' } }}
                        >
                          {t('field_must_read_footer_title')}
                        </Heading5>
                        <Body5>{t('field_must_read_footer_description')}</Body5>
                        <Button
                          variant="secondary"
                          css={{
                            mt: '$10',
                            '@lg': { mt: '$8' },
                            background: isConfirmedAsRead
                              ? '#E1FFF6'
                              : undefined,
                            borderColor: isConfirmedAsRead
                              ? '$successLighten30'
                              : undefined,
                            color: isConfirmedAsRead ? '$success' : undefined,
                            outline: isConfirmedAsRead
                              ? '0 !important'
                              : undefined,
                            pointerEvents: isConfirmedAsRead
                              ? 'none'
                              : undefined
                          }}
                          onClick={onConfirmAsRead}
                        >
                          <Box as="span" className="icon icon-left">
                            <CheckmarkIcon16 />
                          </Box>
                          {t('field_must_read_footer_cta')}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  <Grid>
                    <Space
                      mt="3XL"
                      css={{
                        gc: '2 / span 10',
                        gcSm: '1 / span 12',
                        d: 'flex',
                        flexDirection: 'column',
                        gap: '$4',
                        alignItems: 'center',
                        justifyContent: article?.categories?.length
                          ? 'space-between'
                          : 'flex-end',
                        '@md': {
                          flexDirection: 'row'
                        }
                      }}
                    >
                      {article?.categories?.length > 0 && (
                        <Box
                          css={{
                            d: 'flex',
                            gap: '$2',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                          }}
                        >
                          <Body5 css={{ mr: '$2' }}>
                            {t('field_news_categories_text')}:
                          </Body5>
                          {assignedCategories?.map((categoryLabel) => (
                            <Tag key={categoryLabel}>{categoryLabel}</Tag>
                          ))}
                        </Box>
                      )}

                      <ArticleInfoActions
                        article={article}
                        refetchArticle={refetchArticle}
                        scrollToComments={scrollToComments}
                        css={{ ml: 'initial' }}
                      />
                    </Space>
                  </Grid>
                </Space>
              </AnimatedNewsArticleDetailsCard>
            )}
          </AnimatePresence>
          {/* <Space
            mt="MD"
            mb="2XL"
            css={{ d: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid>
              <Box
                css={{
                  gc: '2 / span 10',
                  gcSm: '1 / span 12'
                }}
              >
                <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
                  <ArticleInfoActions article={article} />
                </Box>
              </Box>
            </Grid>
          </Space> */}
        </Container>
      </ContainerPadding>

      <div ref={commentsBox}>
        {params?.articleId && (
          <ArticleComments
            articleId={params.articleId}
            commentCount={article?.commentCount}
            onCommentPosted={refetchArticle}
            type={NEWS_ARTICLE_TYPE}
          />
        )}
      </div>
    </Box>
  )
}
