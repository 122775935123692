import { MouseEvent, useMemo } from 'react'
import { useMedia } from 'react-use'
import { Body5, Body6, Box, CloseIcon16, Heading5 } from '@sefar/design-system'
import { AI_HOST_URL } from '../../pages/ai-assist/api/consts'
import { MessageMetaData } from '../../pages/ai-assist/api/types'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'

export const ResourcesDrawer = ({
  showMessageResources,
  onCloseDrawer
}: {
  showMessageResources?: MessageMetaData[]
  onCloseDrawer: () => void
}) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const isOpen = !!showMessageResources
  const pdfResourcesInfo = useMemo(
    () =>
      (showMessageResources ?? []).map((resource) => ({
        url: `${AI_HOST_URL}${resource?.meta?.source?.replace('/home/ubuntu/nova', '')}${resource?.meta?.loc.pageNumber ? `#page=${resource?.meta?.loc.pageNumber}` : ''}`,
        fileName: resource?.meta.fileName,
        ...resource?.meta?.pdf?.info,
        ...resource?.meta?.loc
      })),
    [showMessageResources]
  )

  const onCloseDrawerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onCloseDrawer()
  }

  const width = isLg ? '380px' : '100%'

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        w: isOpen ? width : 0,
        flexShrink: 0,
        marginTop: '-64px',
        background: '#222121',
        color: '$white',
        zIndex: 30
      }}
    >
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          w: width,
          px: '30px',
          py: '20px',
          opacity: isOpen ? 1 : 0,
          borderTop: '1px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        <Heading5>Base for answer</Heading5>
        <Box css={{ cursor: 'pointer' }} onClick={onCloseDrawerClick}>
          <CloseIcon16 />
        </Box>
      </Box>
      <Box
        css={{
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          w: width,
          px: '30px',
          py: '46px',
          gap: '50px',
          opacity: isOpen ? 1 : 0,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box css={{ d: 'flex', flexDirection: 'column', gap: '30px' }}>
          {pdfResourcesInfo.map((pdfResourceInfo) => {
            const { fileName, lines, pageNumber } = pdfResourceInfo

            return (
              <Box>
                <Body6 css={{ color: '$neutralLighten60', mb: '2px' }}>
                  {fileName?.endsWith('.pdf') ? 'PDF' : 'Document'}
                  {' | '}
                  {pageNumber
                    ? `Page ${pageNumber} ${lines ? ` (lines ${lines.from}-${lines.to})` : ''}`
                    : ''}
                </Body6>
                <Body5
                  as="a"
                  target="_blank"
                  href={pdfResourceInfo?.url}
                  key={pdfResourceInfo?.CreationDate}
                  css={{
                    d: 'block',
                    color: '$white',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                  }}
                  fontWeight="bold"
                >
                  {pdfResourceInfo?.Title || pdfResourceInfo?.fileName}
                </Body5>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
