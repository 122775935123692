import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './app/app'
import {
  SpinnerContextProvider,
  ToastContextProvider
} from '@sefar/design-system'
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import './styles.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <DndProvider options={HTML5toTouch}>
    <ToastContextProvider>
      <SpinnerContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SpinnerContextProvider>
    </ToastContextProvider>
  </DndProvider>
)
