import React, { ForwardedRef } from 'react'
import { styled } from '../../../../../stitches.config'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import { ChevronLeftIcon16, ChevronRightIcon16 } from '../icons'
import { Box } from '../utils'
import { CSS } from '@stitches/react'

export const Carousel = styled(Swiper, {
  height: '100%',
  '& .swiper-pagination-bullet': {
    background: '$white',
    opacity: 0.4
  },
  '& .swiper-pagination-bullet-active': {
    opacity: 1
  }
})
export const CarouselSlide = styled(SwiperSlide)

const CarouselNavigationButtonStyles = {
  zIndex: '$sliderNavigation',
  position: 'absolute',
  padding: '$2',
  cursor: 'pointer',
  '&.swiper-button-disabled': {
    color: '$neutralLighten30',
    cursor: 'not-allowed'
  },
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover:not(.swiper-button-disabled)': {
      backgroundColor: '$buttonBackgroundBlackBlur',
      borderRadius: '$4'
    }
  }
}

export const CarouselNavigationPrev = React.forwardRef(
  ({ css, ...props }: { css: CSS }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        css={{
          ...CarouselNavigationButtonStyles,
          left: 0,
          ...(css || {})
        }}
        {...props}
      >
        <ChevronLeftIcon16 />
      </Box>
    )
  }
)

export const CarouselNavigationNext = React.forwardRef(
  ({ css, ...props }: { css: CSS }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        css={{
          ...CarouselNavigationButtonStyles,
          right: 0,
          ...(css || {})
        }}
        {...props}
      >
        <ChevronRightIcon16 />
      </Box>
    )
  }
)
