import { useMemo, MouseEvent } from 'react'
import dateFns from 'date-and-time'
import { useMedia } from 'react-use'
import {
  Body6,
  Body7,
  Box,
  LayoutLeftIcon24,
  MessageIcon20
} from '@sefar/design-system'
import { isDaysAgo } from '../utils'
import { ThreadsHistoryItem } from '../../pages/ai-assist/api/types'
import { useNavigate } from 'react-router-dom'
import { AIAssistPageUrl } from '../../app'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'

export const HistoryDrawer = ({
  isOpen,
  onToggleDrawer,
  onChangeThread,
  historyData
}: {
  isOpen: boolean
  onToggleDrawer: () => void
  onChangeThread: (threadId: number) => void
  historyData: ThreadsHistoryItem[]
}) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const navigate = useNavigate()
  const groupedThreads = useMemo(() => {
    if (historyData.length) {
      const recentThreads: Record<string, ThreadsHistoryItem[]> = {}

      historyData.forEach((thread) => {
        const threadDate = new Date(thread.createdAt)

        if (dateFns.isSameDay(threadDate, new Date())) {
          if (!recentThreads.Today) {
            recentThreads.Today = []
          }
          recentThreads.Today.push(thread)
        } else if (isDaysAgo(threadDate, 1)) {
          if (!recentThreads.Yesterday) {
            recentThreads.Yesterday = []
          }
          recentThreads.Yesterday.push(thread)
        } else {
          const monthlyGroupName = dateFns.format(threadDate, 'MMMM YYYY')

          if (!recentThreads[monthlyGroupName]) {
            recentThreads[monthlyGroupName] = []
          }

          recentThreads[monthlyGroupName].push(thread)
        }
      })

      return recentThreads
    } else {
      return {}
    }
  }, [historyData])

  const onToggleDrawerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onToggleDrawer()
  }

  const width = isLg ? '300px' : '100%'

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        w: isOpen ? width : 0,
        flexShrink: 0,
        marginTop: '-64px',
        background: '#222121',
        color: '$white',
        zIndex: 30
      }}
    >
      <Box
        css={{
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          w: width,
          px: '30px',
          py: '60px',
          gap: '50px',
          opacity: isOpen ? 1 : 0,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box css={{ d: 'flex', flexDirection: 'column', gap: '50px' }}>
          {Object.entries(groupedThreads).map(([groupName, groupThreads]) => (
            <Box
              key={groupName}
              css={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
            >
              <Body7 fontWeight="bold">{groupName}</Body7>
              {groupThreads.map((groupThread) => (
                <Box
                  key={groupThread.id}
                  css={{
                    d: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/${AIAssistPageUrl}/${groupThread.id}`)
                    onToggleDrawer()
                  }}
                >
                  <MessageIcon20 style={{ flexShrink: 0 }} />
                  <Body6
                    css={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {groupThread.title}
                  </Body6>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          h: '90px',
          px: '30px',
          borderTop: '1px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        <Box css={{ cursor: 'pointer' }} onClick={onToggleDrawerClick}>
          <LayoutLeftIcon24 />
        </Box>
      </Box>
    </Box>
  )
}
