import {
  Body5,
  Box,
  Button,
  Container,
  ContainerPadding,
  Grid,
  Heading4,
  LazyImage,
  Space,
  TextArea,
  TextLink,
  ToastTypeEnum,
  EditIcon16,
  TrashcanIcon24,
  ReplyIcon16,
  useToastContext
} from '@sefar/design-system'
import {
  addArticleComment,
  deleteArticleComment,
  NewsArticleComment,
  updateArticleComment,
  useArticleComments
} from '../api'
import { AuthorCard } from './common/author'
import AvatarPlaceholder from '../../assets/avatar-placeholder-blue.jpg'
import React, { useState } from 'react'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../stitches.config'
import { useConfigStore } from '../state/config'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import { useTranslate } from '../hooks/useTranslate'
import { UpdateCommentDialog } from './update-comment-dialog'
import { LikesCounter } from './article/article-info'

export function ArticleComments({
  articleId,
  type,
  commentCount,
  onCommentPosted
}: {
  articleId: string
  type: string
  commentCount: number
  onCommentPosted?: () => void
}) {
  const { t } = useTranslate()
  const [commentToUpdate, setCommentToUpdate] = useState<{
    id: string
    initialValue: string
  }>()
  const {
    comments,
    isLoading: loadingComments,
    mutate
  } = useArticleComments(articleId)

  const onCommentAdded = () => {
    mutate(true)
    onCommentPosted && onCommentPosted()
  }

  return (
    <ContainerPadding css={{ backgroundColor: '$neutralLighten97' }}>
      {loadingComments ? null : (
        <Container>
          <Space mt="XL">
            <Grid>
              <Box
                css={{
                  py: '$12',
                  '@md': {
                    py: '$20'
                  },
                  '@lg': {
                    py: '$30'
                  },
                  gc: '2 / span 10',
                  gcSm: '1 / span 12'
                }}
              >
                <Heading4 css={{ mb: '$8' }}>
                  {commentCount ?? 0} {t('field_comments_count_of_comments')}
                </Heading4>

                <EditComment
                  articleId={articleId}
                  type={type}
                  onCommentAdded={onCommentAdded}
                />

                <UpdateCommentDialog
                  comment={commentToUpdate}
                  onCommentUpdate={async (updatedComment: string) => {
                    const commentId = commentToUpdate?.id
                    setCommentToUpdate(undefined)

                    if (commentId) {
                      await updateArticleComment(
                        commentId,
                        updatedComment,
                        type,
                        articleId
                      )
                      mutate(true)
                      onCommentPosted?.()
                    }
                  }}
                  onClose={() => setCommentToUpdate(undefined)}
                />

                {comments?.map((comment: NewsArticleComment, id: number) => (
                  <div key={id}>
                    <CommentCard
                      comment={comment}
                      articleId={articleId}
                      type={type}
                      onCommentAdded={onCommentAdded}
                      onEdit={(
                        commentId: string,
                        initialCommentText: string
                      ) => {
                        setCommentToUpdate({
                          id: commentId,
                          initialValue: initialCommentText
                        })
                      }}
                      onDelete={async (commentId: string) => {
                        await deleteArticleComment(commentId)
                        mutate(true)
                        onCommentPosted?.()
                      }}
                    />
                  </div>
                ))}
              </Box>
            </Grid>
          </Space>
        </Container>
      )}
    </ContainerPadding>
  )
}

export function CommentCard({
  articleId,
  type,
  comment,
  onCommentAdded,
  onEdit,
  onDelete
}: {
  comment: NewsArticleComment
  type: string
  articleId: string
  onCommentAdded?: () => void
  onEdit: (commentId: string, initialCommentText: string) => void
  onDelete: (commentId: string) => void
}) {
  const { me: user } = useConfigStore()
  const { t } = useTranslate()
  const [reply, setReply] = useState<boolean>(false)

  const isCommentChangeAvailable = comment?.author?.id === user?.id

  return (
    <>
      <ViewComment
        comment={comment}
        onReply={() => setReply(true)}
        onEdit={
          isCommentChangeAvailable
            ? () => onEdit(comment.id, comment.text)
            : undefined
        }
        onDelete={
          isCommentChangeAvailable ? () => onDelete(comment.id) : undefined
        }
      />

      {comment?.replies?.map((replyComment) => (
        <Space css={{ ml: '$10' }}>
          <ViewComment
            comment={replyComment}
            onEdit={
              replyComment?.author?.id === user?.id
                ? () => onEdit(replyComment.id, replyComment.text)
                : undefined
            }
            onDelete={
              replyComment?.author?.id === user?.id
                ? () => onDelete(replyComment.id)
                : undefined
            }
          />
        </Space>
      ))}

      {reply && (
        <Space css={{ ml: '$10' }}>
          <EditComment
            articleId={articleId}
            type={type}
            placeholder={t('field_comment_add_reply_to_text', [
              comment?.author?.firstName ?? '',
              comment?.author?.lastName ?? ''
            ])}
            parentCommentId={comment.id}
            onCancel={() => {
              setReply(false)
            }}
            onCommentAdded={onCommentAdded}
          />
        </Space>
      )}
    </>
  )
}

export function ViewComment({
  comment,
  onReply,
  onEdit,
  onDelete
}: {
  comment: NewsArticleComment
  onReply?: () => void
  onEdit?: () => void
  onDelete?: () => void
}) {
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)
  const { t } = useTranslate()

  return (
    <Box
      id={comment.id}
      css={{
        backgroundColor: '$white',
        p: '$6',
        mb: '$4',
        d: 'flex',
        gap: '$2',
        color: '$neutral',
        borderRadius: '$6'
      }}
    >
      <Box css={{ flex: 1 }}>
        <Space mb="2XS">
          <Box
            css={{
              d: 'flex',
              justifyContent: 'space-between',
              '@sm': { flexWrap: 'wrap' }
            }}
          >
            <AuthorCard
              id={comment?.author?.id}
              image={comment?.author?.image}
              firstName={comment?.author?.firstName}
              lastName={comment?.author?.lastName}
              country={comment?.author?.country}
              date={comment.date}
              status={comment?.author?.status}
            />
            <Box css={{ d: 'flex', gap: '$2', marginLeft: 'auto' }}>
              {onEdit && (
                <TextLink
                  className="no-underline"
                  css={{ pt: '2px' }}
                  onClick={onEdit}
                >
                  <span className="icon icon-left">
                    <EditIcon16 />
                  </span>
                </TextLink>
              )}

              {onDelete && (
                <TextLink
                  css={{ mr: '$2' }}
                  className="no-underline"
                  onClick={onDelete}
                >
                  <span className="icon icon-left">
                    <TrashcanIcon24 width={18} height={18} />
                  </span>
                </TextLink>
              )}

              {onReply && (
                <TextLink className="no-underline" onClick={onReply}>
                  <span className="icon icon-left">
                    <ReplyIcon16 />
                  </span>
                  {t('field_reply')}
                </TextLink>
              )}

              <LikesCounter article={comment} />
            </Box>
          </Box>
        </Space>

        <Body5 css={{ pl: '$10', pr: '$6', '@sm': { pl: '$6', pr: 0 } }}>
          <Clamp
            withToggle
            lines={isMd ? 4 : 5}
            maxLines={Number.MAX_SAFE_INTEGER}
            showMoreElement={({ toggle }: { toggle: () => void }) => (
              <TextLink variant="small" onClick={toggle}>
                {t('field_show_more')}
              </TextLink>
            )}
            showLessElement={({ toggle }: { toggle: () => void }) => (
              <TextLink variant="small" onClick={toggle}>
                {t('field_show_less')}
              </TextLink>
            )}
          >
            <Box
              css={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: comment.text }}
            />
          </Clamp>
        </Body5>
      </Box>
    </Box>
  )
}

export function EditComment({
  articleId,
  type,
  onCommentAdded,
  onCancel,
  placeholder,
  parentCommentId
}: {
  articleId: string
  type: string
  onCommentAdded?: () => void
  onCancel?: () => void
  placeholder?: string
  parentCommentId?: string
}) {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const [addingCommentInProgress, setAddingCommentInProgress] =
    useState<boolean>(false)
  const [commentValue, setCommentValue] = useState<string>('')
  const { me: user } = useConfigStore()

  const onSaveArticleComment = () => {
    setAddingCommentInProgress(true)
    addArticleComment(articleId!, commentValue, type, parentCommentId)
      .then((res) => res.json())
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: t('field_comment_added_toast_text'),
            type: ToastTypeEnum.success
          })
          setCommentValue('')
        }
        onCommentAdded && onCommentAdded()
      })
      .catch(() => {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      })
      .finally(() => {
        setTimeout(() => {
          setAddingCommentInProgress(false)
        }, 0)
      })
  }

  return (
    <Space
      mb="XS"
      css={{
        d: 'flex',
        gap: '$2',
        backgroundColor: '$white',
        p: '$6',
        borderRadius: '$6',
        border: '1px solid $destructiveDisabled',
        boxShadow: '$card'
      }}
    >
      <Space
        css={{
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden'
        }}
      >
        <LazyImage src={user?.image || AvatarPlaceholder} aspectRatio="1 / 1" />
      </Space>
      <Box
        css={{
          d: 'flex',
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <TextArea
          autoFocus={!!parentCommentId}
          value={commentValue}
          onChange={({ currentTarget }) => setCommentValue(currentTarget.value)}
          id={`${parentCommentId || ''}_comment`}
          placeholder={placeholder || t('field_comments_add_your_comment')}
          borderless
          css={{ resize: 'none', boxShadow: 'none' }}
        />
        <Box css={{ d: 'flex', gap: '$2', mt: commentValue ? '$5' : '0' }}>
          {parentCommentId && commentValue && (
            <Button variant="secondary" onClick={() => onCancel && onCancel()}>
              {t('field_cancel')}
            </Button>
          )}
          {commentValue && (
            <Button
              disabled={!commentValue || addingCommentInProgress}
              onClick={onSaveArticleComment}
            >
              {parentCommentId ? t('field_reply') : t('field_post')}
            </Button>
          )}
        </Box>
      </Box>
    </Space>
  )
}
